import { format } from "date-fns";
import accounting from "accounting";

const currency = (value, currencyName = "人民币") => {
  switch (currencyName) {
    case "人民币":
      return accounting.formatMoney(value, "¥ ");
    case "美元":
      return accounting.formatMoney(value, "$ ");
    case "欧元":
      return accounting.formatMoney(value, "€ ");
    case "港元":
      return accounting.formatMoney(value, "HK$ ");
    default:
      return accounting.formatMoney(value, "¥ ");
  }
};

const decimal = (value, precision = 2) => accounting.toFixed(value, precision);

const datetimeFormat = (value, pattern) =>
  value ? format(new Date(value), pattern) : "";

const date = value => (value ? format(new Date(value), "yyyy-MM-dd") : "");

const month = value => (value ? format(new Date(value), "yyyy-MM") : "");

const date_time = value =>
  value ? format(new Date(value), "yyyy年MM月dd日 HH:mm:ss") : "";

const month_CH = value => (value ? format(new Date(value), "yyyy年MM月") : "");

const verified = value => (value ? "通过" : "未通过");

const NumberFormat = value => {
  if (!value) {
    return "0";
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  return intPartFormat;
};

export default {
  currency,
  decimal,
  datetimeFormat,
  date,
  month_CH,
  month,
  verified,
  NumberFormat,
  date_time
};
