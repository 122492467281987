import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import filters from "./filter";
import "./plugins/svgIcon";
import "./plugins/rem";
import "./styles/global.scss";
import "./permission";
import { setStorage, getStorage } from "./utils/common";

// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$setStorage = setStorage;
Vue.prototype.$getStorage = getStorage;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
