<template>
  <el-menu-item
    :index="getMenuItem(menuItem).path"
    @click="handleMenuClick(menuItem)"
    class="menu-item"
    :class="$route.name === getMenuItem(menuItem).name ? 'is-active' : ''"
  >
    <i
      class="menu-icon"
      :class="$route.name === getMenuItem(menuItem).name ? 'is-active' : ''"
      v-if="getMenuItem(menuItem).meta.iconSvg"
      ><svg-icon :iconClass="getMenuItem(menuItem).meta.iconSvg"></svg-icon
    ></i>

    <span slot="title">{{ getMenuItem(menuItem).meta.title }}</span>
  </el-menu-item>
</template>

<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onMenuClick(menuItem) {
      this.$router.push(menuItem.path).catch(() => {});
      // this.$emit("menuClick");
    },
    getMenuItem(menuItem) {
      if (menuItem.meta) {
        return menuItem;
      } else {
        return menuItem.children[0];
      }
    },
    handleMenuClick(item) {
      let target = this.getMenuItem(item);
      if (target.path !== this.$route.path) {
        this.$router.push({ path: target.path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
.menu-item {
  color: $custom-menu-text-color;
  background-color: $theme_color;
  &:hover,
  &:focus {
    outline: none;
    background-color: $custom-menu-hover-color !important;
    color: $custom-menu-text-active-color !important;
  }
}
.menu-icon {
  vertical-align: middle;
  margin-right: 20px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: $custom-menu-text-color;
}
.is-active {
  outline: none;
  background-color: $custom-menu-hover-color !important;
  color: $custom-menu-text-active-color !important;
}
</style>