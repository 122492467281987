<template>
  <el-menu
    class="base-side-bar"
    :default-active="$route.path"
    :collapse="isCollapse"
  >
    <!-- background-color="#009d68"
  text-color="#fff"
  active-text-color="#fff" -->
    <template v-for="item in meuns">
      <MenuItem
        v-if="onlyOneMenuItem(item)"
        :menuItem="item"
        :key="item.path"
      />
      <SubMenu v-if="isMenuGroup(item)" :menuItem="item" :key="item.path" />
    </template>
  </el-menu>
</template>

<script>
import MenuItem from "./item/MeunItem";
import SubMenu from "./item/SubMenu";
export default {
  components: {
    SubMenu,
    MenuItem,
  },
  computed: {
    meuns() {
      return this.$store.state.app.menus;
    },
    isCollapse() {
      return this.$store.state.app.isSideBarCollapse;
    },
  },
  methods: {
    // 区分有效单个菜单
    onlyOneMenuItem(menuItem) {
      if (menuItem.meta) {
        return menuItem.meta.isMenu;
      } else if (
        menuItem.children &&
        menuItem.children.length > 0 &&
        !menuItem.showChildren &&
        menuItem.onlyExtentdLayout
      ) {
        return menuItem.children[0].meta.isMenu;
      } else {
        return false;
      }
    },
    // 区分有效的菜单组
    isMenuGroup(menuItem) {
      if (
        menuItem.children &&
        menuItem.children.length > 0 &&
        !menuItem.meta &&
        menuItem.showChildren
      ) {
        if (menuItem.children.length <= 0) {
          return false;
        }
        // 检查该分组下是否所有的子菜单都设置为不显示的
        let allChildrenUnshow = menuItem.children.every(
          (item) => !item.meta.isMenu
        );
        if (allChildrenUnshow) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
.base-side-bar {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  border-right: 0 solid transparent;
  color: #fff;
  background-color: transparent !important;
  overflow-x: hidden;
  overflow-y: auto;
  ::v-deep {
    .el-submenu .el-menu {
      background: none;
    }
    .el-submenu__title,
    .el-menu-item {
      height: 45px;
      line-height: 45px;
      color: #fff;
      padding: 0 10px;
    }
  }
  .menu-icon {
    vertical-align: middle;
    margin-right: 20px;
    width: 24px;
    height: 100%;
    text-align: left;
    font-size: 18px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $theme_color ;
    background-clip: padding-box;
    border-radius: 20px;
    min-height: 28px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $theme_hover_color;
  }
}
</style>
