<template>
  <el-form :model="userInfo" class="demo-ruleForm">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="电话号码：" prop="phoneNo">
          <span>{{ userInfo.phoneNo }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="用户登录名：" prop="username">
          <span>{{ userInfo.username }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否已激活：" prop="activated">
          <el-tag type="success" v-if="userInfo.activated">已激活</el-tag>
          <el-tag type="danger" v-if="!userInfo.activated">未激活</el-tag>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="公司名称：" prop="companyName">
          <span>{{ userInfo.companyName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="创建时间：" prop="createdAt">
          <span>{{ userInfo.createdAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="创建人：" prop="createdByName">
          <span>{{ userInfo.createdByName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="部门名称：" prop="departmentName">
          <span>{{ userInfo.departmentName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="初始密码：" prop="initPassword">
          <span>{{ userInfo.initPassword }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="最后修改时间：" prop="initPassword">
          <span>{{ userInfo.lastModifiedAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="姓名：" prop="name">
          <span>{{ userInfo.name }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="微信小程序是否已绑定：" prop="wxMiniAppBound">
          <el-tag type="success" v-if="userInfo.wxMiniAppBound">已绑定</el-tag>
          <el-tag type="danger" v-if="!userInfo.wxMiniAppBound">未绑定</el-tag>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
};
</script>

<style>
</style>