/* eslint-disable */
import router, { contantsRoutes, asyncRoutes } from "./router"
import {
  getToken, GetUserInfo, filterAsyncRoutes, logout, SetCurrentRoute,
  GetCurrentRoute
} from "./utils/auth";
import store from "./store";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false });// NProgress Configuration

const whiteList = ['/login'];

function redirectToLogin(next, to) {
  next({
    path: '/login',
    query: {
      redirect: to.fullPath,
      ...to.query,
    },
    params: to.params
  })
}

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken() !== null ? true : false
  const hasRoles = store.state.auth.roles.length > 0 ? true : false
  if (hasToken) {
    if (hasRoles) {
      // 防止登录后，手动输入地址跳转登录页
      if (to.path === '/login') {
        if (to.query.redirect && to.query.redirect !== "") {
          // 如果有重定向，则按照重定向
          next({
            path: to.query.redirect
          })
        } else {
          // 如果是手动输入登录页地址，并且不是重定向的情况就跳转首页
          next('/')
        }
      }
      if (to.path === '/') {
        document.title = `欢迎 ${store.getters.userInfo.name} 登录`
      } else {
        document.title = `当前访问 ${to.meta.title}`
      }
      let mRoles = filterAsyncRoutes(asyncRoutes, store.state.auth.roles)
      store.commit('app/SetMenus', [...contantsRoutes, ...mRoles])
      // 记录当前访问的页面的路由
      SetCurrentRoute({
        path: to.path,
        fullPath: to.fullPath,
        query: to.query,
        params: to.params
      })
      next()
    } else {
      try {
        let roles = await GetUserInfo(getToken());
        if (roles.length <= 0) {
          next('/login')
          return false
        }
        let mRoles = filterAsyncRoutes(asyncRoutes, roles)
        store.commit('app/SetMenus', [...contantsRoutes, ...mRoles])
        router.addRoutes(mRoles)
        next({
          ...to,
          replace: true
        })
      } catch (error) {
        logout();
        redirectToLogin(next, to)
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      let route = GetCurrentRoute()
      if (route) {
        redirectToLogin(next, to)
      } else {
        redirectToLogin(next, to)
      }
    }
  }

})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})