<template>
  <el-submenu :index="menuItem.path" :key="menuItem.path" class="sub-menu">
    <template slot="title">
      <i class="menu-icon" v-if="menuItem.iconSvg"
        ><svg-icon :iconClass="menuItem.iconSvg"></svg-icon
      ></i>
      <span>{{ menuItem.groupTitle }}</span>
    </template>
    <template v-for="subItem in menuItem.children">
      <MenuItem
        v-if="subItem.meta.isMenu && !subItem.meta.showChildren"
        :menuItem="subItem"
        :key="subItem.path"
      />
      <SubMenu
        v-if="
          subItem.meta.isMenu && subItem.meta.showChildren && subItem.children
        "
        :menuItem="subItem"
        :key="subItem.path"
      />
    </template>
  </el-submenu>
</template>

<script>
import MenuItem from "./MeunItem";
import SubMenu from "./SubMenu";
export default {
  name: "SubMenu",
  components: {
    SubMenu,
    MenuItem,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    subMenuTitleClick(item) {
      let path = "";
      if (item.redirect && item.redirect !== "") {
        path = item.redirect;
      } else {
        path = item.path;
      }
      if (path !== this.$route.path) {
        this.$router.push({ path: path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
.sub-menu {
  ::v-deep {
    .el-submenu__title:hover {
      background-color: $custom-menu-hover-color;
      color: $custom-menu-text-active-color;
    }
    .el-submenu__icon-arrow {
      color: $custom-menu-text-color;
    }
  }
}
.menu-icon {
  vertical-align: middle;
  margin-right: 20px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: $custom-menu-text-color;
}
</style>